<template>
  <div class="d-flex-column align-center main rel-wrap">
    <!-- only have header-bg if no guy   -->
    <Profile :person="currentProfile" class="profile" />
    <el-form label-position="top" class="main full-width input-underline" :key="componentKey">
      <el-card v-if="!profileScreen">
        <div class="mw-400">
          <div class="main">
            <div>
              <h4>Registration Details</h4>

              <LogoTitleSubtitle
                v-if="this.current.entity && current.entity.entityType === 'club'"
                :title="current.entity.name"
                :subtitle="
                  currentProduct
                    ? `Season Registration: ${formatMoney(currentProduct.totalPrice)}`
                    : ''
                "
                :img="imgEndpoint(current.entity._id)"
              />
              <LogoTitleSubtitle
                v-if="this.current.entity && current.entity.entityType === 'program'"
                :title="current.entity.name"
                :subtitle="`Program Registration: ${formatMoney(currentProduct.totalPrice)}`"
                :img="current.entity.img"
              />
            </div>
            <SuperInput
              id="regoDetailsInput"
              v-model="forms.typeOfRegistrationForm.typeOfRegistration.value"
              :field="forms.typeOfRegistrationForm.typeOfRegistration"
              :disabled="!!forms.typeOfRegistrationForm.typeOfRegistration.disabled"
              :errors.sync="forms.typeOfRegistrationForm.typeOfRegistration.errors"
            />
            <small v-if="clearanceRequired">
              This member type requires a clearance, please submit one below or try a different
              member type.
            </small>
            <el-button
              type="primary"
              class="full-width mt-1"
              @click="
                $router.push({
                  name: 'clearanceform',
                  params: {
                    memberId: current.id,
                    memberType: currentMemberType,
                    toClubId: current.entity._id,
                  },
                })
              "
              v-if="clearanceRequired"
              >Request Clearance</el-button
            >
          </div>
        </div>
      </el-card>

      <el-card>
        <div class="mw-400">
          <h4>Participant Details</h4>
          <div class="main">
            <SuperInput
              :field="forms.participantForm.firstName"
              v-model="forms.participantForm.firstName.value"
              :errors.sync="forms.participantForm.firstName.errors"
              :disabled="true"
            />
            <SuperInput
              :field="forms.participantForm.middleName"
              v-model="forms.participantForm.middleName.value"
              :errors.sync="forms.participantForm.middleName.errors"
              :disabled="true"
            />
            <SuperInput
              :field="forms.participantForm.lastName"
              v-model="forms.participantForm.lastName.value"
              :errors.sync="forms.participantForm.lastName.errors"
              :disabled="true"
            />
            <SuperInput
              :field="forms.participantForm.preferFirstName"
              v-model="forms.participantForm.preferFirstName.value"
              :errors.sync="forms.participantForm.preferFirstName.errors"
            />
            <SuperInput
              :field="forms.participantForm.preferLastName"
              v-model="forms.participantForm.preferLastName.value"
              :errors.sync="forms.participantForm.preferLastName.errors"
            />
            <SuperInput
              :field="forms.participantForm.dob"
              v-model="forms.participantForm.dob.value"
              :errors.sync="forms.participantForm.dob.errors"
              :disabled="true"
            />
            <SuperInput
              :field="forms.participantForm.gender"
              v-model="forms.participantForm.gender.value"
              :errors.sync="forms.participantForm.gender.errors"
              :disabled="genderDisabled"
            />
            <SuperInput
              :field="forms.participantForm.email"
              v-model="forms.participantForm.email.value"
              :errors.sync="forms.participantForm.email.errors"
            />
            <p class="mobile">Mobile</p>
            <vue-tel-input
              v-model="forms.participantForm.mobile.value"
              :dropdownOptions="props"
              :preferredCountries="preferredCountries"
              :autoFormat="true"
              :validCharactersOnly="true"
              mode="international"
              @input="handlePaste"
              @validate="validateMobile"
              :class="!isValidInternationalMobile ? 'red' : ''"
            ></vue-tel-input>
            <p v-if="!isValidInternationalMobile" class="invalidMobile">Invalid Mobile Number</p>

            <div class="current-address mt-2">
              Current Address:
              <br />
              <span v-for="(line, index) in currentAddress" :key="index">{{
                line !== ", " ? line : ""
              }}</span>
              <br />
              <div>
                <button
                  class="button-invisible button-address"
                  :class="addressError ? 'red' : ''"
                  @click.prevent="toggleManualAddress"
                >
                  {{ manualAddress ? "Search for Address" : "Enter Address Manually" }}
                </button>
              </div>
            </div>
            <AddressAutocomplete
              v-if="!manualAddress"
              id="address-autocomplete"
              class="autocomplete"
              :enableGeolocation="true"
              placeholder="Search for address"
              @placechanged="getAddressData"
            />
            <div v-if="manualAddress">
              <SuperInput
                :field="forms.participantForm.addressLine1"
                v-model="forms.participantForm.addressLine1.value"
                :errors.sync="forms.participantForm.addressLine1.errors"
              />
              <SuperInput
                :field="forms.participantForm.addressLine2"
                v-model="forms.participantForm.addressLine2.value"
                :errors.sync="forms.participantForm.addressLine2.errors"
              />
              <SuperInput
                :field="forms.participantForm.country"
                v-model="forms.participantForm.country.value"
                :errors.sync="forms.participantForm.country.errors"
              />
              <SuperInput
                :field="forms.participantForm.state"
                v-model="forms.participantForm.state.value"
                :errors.sync="forms.participantForm.state.errors"
              />
              <SuperInput
                :field="forms.participantForm.suburb"
                v-model="forms.participantForm.suburb.value"
                :errors.sync="forms.participantForm.suburb.errors"
              />
              <span v-if = "suburbInvalidChoiceError" class="warning"> Please choose a city or suburb from the dropdown </span>
              <SuperInput
                :field="forms.participantForm.postcode"
                v-model="forms.participantForm.postcode.value"
                :errors.sync="forms.participantForm.postcode.errors"
              />
              
            </div>
            <SuperInput
              :field="forms.participantForm.isPrivate"
              v-model="forms.participantForm.isPrivate.value"
              :errors.sync="forms.participantForm.isPrivate.errors"
            />
            <p class="f-14">
              * If set to Yes, your profile will be private on public teams lists and match centres
              across the relevant websites and apps.
            </p>
          </div>
        </div>
      </el-card>

      <el-card
         v-if="this.current.profile ? isMinorConsistent(current.profile.participantForm.dob) : false"
      style="overflow: visible;">
        <div class="mw-400">
          <h4>Parent/Guardian Details</h4>
          <div class="main">
            <SuperInput
              :field="forms.parentForm.name"
              v-model="forms.parentForm.name.value"
              :errors.sync="forms.parentForm.name.errors"
            />
            <SuperInput
              :field="forms.parentForm.email"
              v-model="forms.parentForm.email.value"
              :errors.sync="forms.parentForm.email.errors"
            />
            <p class="mobile">Mobile</p>
            <vue-tel-input
              v-model="forms.parentForm.mobile.value"
              :dropdownOptions="props"
              :preferredCountries="preferredCountries"
              :autoFormat="true"
              :validCharactersOnly="true"
              mode="international"
              @input="handleParentPaste"
              @validate="validateParentMobile"
              :class="!isValidInternationalParentMobile ? 'red' : ''"
            ></vue-tel-input>
            <p v-if="!isValidInternationalParentMobile" class="invalidMobile">Invalid Mobile Number</p>
            <CheckBoxLine
              v-if="
                this.current.profile
                  ? isMinorConsistent(current.profile.participantForm.dob)
                  : false
              "
              v-model="emergencySyncParent"
            >
              <span class="f-14">Make your emergency contact the same as parent/guardian</span>
            </CheckBoxLine>
          </div>
        </div>
      </el-card>

      <el-card v-if="!emergencySyncParent" style="overflow: visible;">
        <div class="mw-400">
          <h4>Emergency Details</h4>
          <div class="main">
            <SuperInput
              :field="forms.emergencyForm.name"
              v-model="forms.emergencyForm.name.value"
              :errors.sync="forms.emergencyForm.name.errors"
            />
            <p class="mobile">Mobile</p>
            <vue-tel-input
              v-model="forms.emergencyForm.mobile.value"
              :dropdownOptions="props"
              :preferredCountries="preferredCountries"
              :autoFormat="true"
              :validCharactersOnly="true"
              mode="international"
              @input="handleEmergencyPaste"
              @validate="validateEmergencyMobile"
              :class="!isValidInternationalEmergencyMobile ? 'red' : ''"
            ></vue-tel-input>
            <p v-if="!isValidInternationalEmergencyMobile" class="invalidMobile">Invalid Mobile Number</p>
            
          </div>
        </div>
      </el-card>

      <el-card>
        <div class="mw-400">
          <h4>Heritage</h4>
          <div class="main">
            <SuperInput
              :field="forms.additionalForm.ethnicity"
              v-model="forms.additionalForm.ethnicity.value"
              :errors.sync="forms.additionalForm.ethnicity.errors"
            />
            <SuperInput
              :field="forms.additionalForm.cobMember"
              v-model="forms.additionalForm.cobMember.value"
              :errors.sync="forms.additionalForm.cobMember.errors"
            />
            <SuperInput
              :field="forms.additionalForm.cobMother"
              v-model="forms.additionalForm.cobMother.value"
              :errors.sync="forms.additionalForm.cobMother.errors"
            />
            <SuperInput
              :field="forms.additionalForm.cobFather"
              v-model="forms.additionalForm.cobFather.value"
              :errors.sync="forms.additionalForm.cobFather.errors"
            />
            <CheckBoxLine v-if="showInternationalHeritage" v-model="internationalHeritage">
              If you are interested in participating in tournaments of international heritage,
              please provide further information
            </CheckBoxLine>
            <SuperInput
              :field="forms.additionalForm.cobMotherGrandMother"
              v-model="forms.additionalForm.cobMotherGrandMother.value"
              :errors.sync="forms.additionalForm.cobMotherGrandMother.errors"
            />
            <SuperInput
              :field="forms.additionalForm.cobMotherGrandFather"
              v-model="forms.additionalForm.cobMotherGrandFather.value"
              :errors.sync="forms.additionalForm.cobMotherGrandFather.errors"
            />
            <SuperInput
              :field="forms.additionalForm.cobFatherGrandMother"
              v-model="forms.additionalForm.cobFatherGrandMother.value"
              :errors.sync="forms.additionalForm.cobFatherGrandMother.errors"
            />
            <SuperInput
              :field="forms.additionalForm.cobFatherGrandFather"
              v-model="forms.additionalForm.cobFatherGrandFather.value"
              :errors.sync="forms.additionalForm.cobFatherGrandFather.errors"
            />
          </div>
        </div>
      </el-card>

      <el-card>
        <div class="mw-400">
          <h4>Medical Information</h4>
          <div class="main">
            <SuperInput
              :field="forms.additionalForm.hasAllergies"
              v-model="forms.additionalForm.hasAllergies.value"
              :errors.sync="forms.additionalForm.hasAllergies.errors"
            />
            <SuperInput
              :field="forms.additionalForm.allowedTreatment"
              v-model="forms.additionalForm.allowedTreatment.value"
              :errors.sync="forms.additionalForm.allowedTreatment.errors"
            />
            <SuperInput
              :field="forms.additionalForm.hasMedical"
              v-model="forms.additionalForm.hasMedical.value"
              :errors.sync="forms.additionalForm.hasMedical.errors"
            />
            <p class="mb-0" v-if="requireMedicalNotes">
              If you or your child has any allergies or medical conditions, please provide further
              details.
            </p>
            <SuperInput
              class="mt-0"
              :field="forms.additionalForm.notes"
              v-model="forms.additionalForm.notes.value"
              :errors.sync="forms.additionalForm.notes.errors"
            />
          </div>
        </div>
      </el-card>

      <el-card v-if="current.entity !== undefined && nationalId === 31">
        <div class="mw-400">
          <h4>Disability Information</h4>
          <div class="main">
            <SuperInput
              :field="forms.additionalForm.hasDisability"
              v-model="forms.additionalForm.hasDisability.value"
              :errors.sync="forms.additionalForm.hasDisability.errors"
            />
            <SuperInput
              :field="forms.additionalForm.disabilityType"
              v-model="forms.additionalForm.disabilityType.value"
              :errors.sync="forms.additionalForm.disabilityType.errors"
            />
          </div>
        </div>
      </el-card>

      <el-card v-if="requireWWC || showWWC">
        <div class="mw-400">
          <h4>Working with Children Check (WWC)</h4>
          <p
            class="f-14"
            v-if="
              !isTFA &&
              $route.name !== 'editprofile' &&
              ((current.profile &&
                current.profile.additionalForm &&
                current.profile.additionalForm.wwcStatus &&
                current.profile.additionalForm.wwcStatus !== 'barred') ||
                !current.profile.additionalForm.wwcStatus)
            "
          >
            It is a requirement to provide a valid Working With Children Check if working with
            children 18 years and younger, this includes any team officials (i.e. coaches, trainers,
            managers, match officials). Rugby League is committed to providing the safest possible
            environment for children, previous Working With Children Check exemptions are no longer
            permissible.
            <br />
            Participants under the age of 18 years of age will NOT be required to provide a Working
            With Children Check. <br />
            <a href="https://www.playrugbyleague.com/safety/" target="_blank">
              Child Safety Policies</a
            >
          </p>
          <p
            v-if="
              $route.name === 'editprofile' &&
              current.profile &&
              current.profile.additionalForm &&
              current.profile.additionalForm.wwcStatus &&
              current.profile.additionalForm.wwcStatus === 'barred'
            "
          >
            For further WWC information, please contact your club or association.
          </p>
          <div
            class="main"
            v-if="
              current.profile &&
              current.profile.additionalForm &&
              current.profile.additionalForm.wwcStatus !== 'barred'
            "
          >
            <SuperInput
              :field="forms.additionalForm.wwcNumber"
              v-model="forms.additionalForm.wwcNumber.value"
              :errors.sync="forms.additionalForm.wwcNumber.errors"
            />
            <SuperInput
              :field="forms.additionalForm.wwcIssuer"
              v-model="forms.additionalForm.wwcIssuer.value"
              :errors.sync="forms.additionalForm.wwcIssuer.errors"
            />
            <SuperInput
              isWWC
              :field="forms.additionalForm.wwcExpiry"
              v-model="forms.additionalForm.wwcExpiry.value"
              :errors.sync="forms.additionalForm.wwcExpiry.errors"
            />
          </div>
        </div>
      </el-card>

      <el-card v-if="profileScreen && verificationPending">
        <div class="mw-400">
          <h4>Supporting Documents</h4>
          <div class="main">
            <el-table
              v-if="hasDocuments"
              empty-text="No documents"
              :data="getCurrentDocuments"
              layout="table"
              class="data-table"
            >
              <el-table-column fixed prop="_id" label="Id"></el-table-column>
              <el-table-column fixed prop="docType" label="Document Type"></el-table-column>
            </el-table>
            <el-button
              type="primary"
              class="full-width"
              @click="displayUploadDocuments = !displayUploadDocuments"
              >Upload Documents</el-button
            >
          </div>
        </div>

        <ModalLayout
          v-if="displayUploadDocuments"
          @close="displayUploadDocuments = false"
          class="modal-parent"
          title="Upload Documents"
        >
          <h3 slot="title">Upload Documents</h3>
          <verification-upload
            v-if="notProgram"
            class="upload-modal"
            @closeModal="displayUploadDocuments = false"
            :inRegoFlow="false"
          />
        </ModalLayout>
      </el-card>

      <el-card v-if="currentProduct && currentProduct.memberType === 'program'">
        <div class="mw-400">
          <h4>Postal Address</h4>
          <div class="main">
            <button @click.prevent="fillAddress" class="button-invisible button-address left">
              Same as residential address
            </button>
            <div class="current-address mt-2">
              Current Address:
              <br />
              <span v-for="(line, index) in currentAddress_program" :key="index">{{
                line !== ", " ? line : ""
              }}</span>
              <br />
              <div>
                <button
                  class="button-invisible button-address"
                  :class="addressError_program ? 'red' : ''"
                  @click.prevent="toggleManualAddress_program"
                >
                  {{ manualAddress_program ? "Search for Address" : "Enter Address Manually" }}
                </button>
              </div>
            </div>
            <AddressAutocomplete
              v-if="!manualAddress_program"
              id="address-autocomplete_postal"
              class="autocomplete"
              :enableGeolocation="true"
              placeholder="Search for address"
              @placechanged="getAddressData_postal"
            />
            <div v-if="manualAddress_program">
              <SuperInput
                :field="forms.participantForm.postalAddressLine1"
                v-model="forms.participantForm.postalAddressLine1.value"
                :errors.sync="forms.participantForm.postalAddressLine1.errors"
              />
              <SuperInput
                :field="forms.participantForm.postalAddressLine2"
                v-model="forms.participantForm.postalAddressLine2.value"
                :errors.sync="forms.participantForm.postalAddressLine2.errors"
              />
              <SuperInput
                :field="forms.participantForm.postalSuburb"
                v-model="forms.participantForm.postalSuburb.value"
                :errors.sync="forms.participantForm.postalSuburb.errors"
              />
              <SuperInput
                :field="forms.participantForm.postalState"
                v-model="forms.participantForm.postalState.value"
                :errors.sync="forms.participantForm.postalState.errors"
              />
              <SuperInput
                :field="forms.participantForm.postalPostcode"
                v-model="forms.participantForm.postalPostcode.value"
                :errors.sync="forms.participantForm.postalPostcode.errors"
              />
              <SuperInput
                :field="forms.participantForm.postalCountry"
                v-model="forms.participantForm.postalCountry.value"
                :errors.sync="forms.participantForm.postalCountry.errors"
              />
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="mt-1">
        <div class="mw-400">
          <CheckBoxLine v-model="usePhoto" ref="photo">
            <p class="f-14">
              I allow my/my child's photo to be used by NRL and/or Touch Football Australia and
              acknowledge all rights in the promotional material are owned by the NRL and/or Touch
              Football Australia.
            </p>
          </CheckBoxLine>
        </div>
        <div v-if="this.nationalId === 31 || profileScreen" class="mw-400">
          <CheckBoxLine v-model="optinTfa" ref="optinTfa">
            <p class="f-14">
              I would like to receive updates, information or offers from Touch Football
              Organisations and partners of Touch Football Organisations and consent to the use of
              my personal information in the manner explained in
              <a
                href="https://touchfootball.com.au/policies/privacy-policy/"
                target="_blank"
                @click.stop
                >Touch Football Australia’s Privacy Policy</a
              >.
            </p>
          </CheckBoxLine>
        </div>
        <div class="mw-400">
          <CheckBoxLine v-model="optinNrl" ref="optinNrl">
            <p class="f-14">
              I would like you to send me and my parent/guardian information (from any of the NRL,
              NRL Clubs and State Organisations) regarding rugby league events, activities and
              offers and consent to the use of my personal information in the manner explained in
              the
              <a href="https://www.nrl.com/privacy-policy" target="_blank" @click.stop
                >NRLs Privacy Policy</a
              >.
            </p>
          </CheckBoxLine>
        </div>
      </el-card>

      <div class="d-flex-column main mw-400 mb-2">
        <div>
          <el-button
            id="nextstep"
            type="primary"
            class="full-width"
            @click="submitForm"
            :disabled="clearanceRequired"
            >{{ profileScreen ? "Save" : "Next" }}</el-button
          >
        </div>
        <div>
          <el-button @click="handleCancel" class="full-width button-invisible" type="success"
            >Cancel</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import map from "lodash/fp/map";
import concat from "lodash/fp/concat";
import includes from "lodash/fp/includes";
import { cloneDeep } from "lodash";
import Profile from "@/components/profile/Profile.vue";
import CheckBoxLine from "@/components/form/fields/CheckBoxLine.vue";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import AddressAutocomplete from "@/components/form/fields/AddressAutocomplete.vue";
import LogoTitleSubtitle from "@/components/LogoTitleSubtitle.vue";
import ModalLayout from "@/layouts/ModalLayout.vue";
import VerificationUpload from "@/views/registration/VerificationUpload.vue";
import { errorOrPassFormset, flattenFormset, makeForm, populateFormset } from "@/utils/forms";
import { isMinorConsistent, isBelowAge } from "@/utils/date/minor";
import { formatMoney } from "@/utils/money";
import {
  genderOptions,
  memberTypes,
  typeOfRegistrationOptions,
  countries,
  countriesWithPNTSOption,
  stateTeams,
  nationalMensTeams,
  nationalWomensTeams,
  ethnicityOptions,
  states,
  disabilityTypes,
} from "@/utils/constants";
import msg from "@/utils/constants/msg";
import { endpointParams } from "@/utils/constants/api";
import { choiceStrategy, emailStrategy, mobileStrategy } from "@/utils/validators/strategy";
import { mapProfile } from "@/utils/helpers/members";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { debounce } from 'lodash';

export default {
  name: "RegistrationForm",
  components: {
    SuperInput,
    Profile,
    CheckBoxLine,
    LogoTitleSubtitle,
    AddressAutocomplete,
    VerificationUpload,
    ModalLayout,
  },
  props: {
    profileScreen: Boolean,
    id: [String, Number],
  },
  mounted() {
    
    if (this.profileScreen && this.current.id !== parseInt(this.id)) {
      this.LOADING(true);
      this.$http
        .get("/nrl/api/v1/portal/members")
        .then((membersResponse) => {
          const myProfile = membersResponse.data.data
            .map(({ _id: id, profile, verification, documents, activeRegos }) => ({
              id,
              verification,
              documents,
              activeRegos,
              ...profile,
              meta: { ...profile.meta },
            }))
            .filter((x) => x.id === parseInt(this.id))[0];
          if (myProfile) {
            this.updateCurrent({
              profile: mapProfile(myProfile),
              documents: myProfile.documents,
            });
            this.getPerson();
            this.fillData();
            this.$set(
              this.forms.typeOfRegistrationForm.typeOfRegistration,
              "options",
              this.activeMemberTypes
            );
            // this.$set(
            //   this.forms.typeOfRegistrationForm.typeOfRegistration,
            //   'value',
            //   this.currentProduct.memberType,
            // );
            this.usePhoto = this.current.profile.usePhoto || false;
            this.optinNrl = this.current.profile.optinNrl || false;
            this.optinTfa = this.current.profile.optinTfa || false;
          } else {
            this.$router.push({ name: "profile" });
          }
          this.LOADING(false);
        })
        .catch(() => {
          this.PUSH_NOTIFICATION({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: "warning",
          });
          this.LOADING(false);
        });
    } else {
      this.getPerson();
      this.fillData();
      this.$set(
        this.forms.typeOfRegistrationForm.typeOfRegistration,
        "options",
        this.activeMemberTypes
      );
      // this.$set(
      //   this.forms.typeOfRegistrationForm.typeOfRegistration,
      //   'value',
      //   this.currentProduct.memberType,
      // );
      // TODO: maybe refactor below into a check on form population
      if (!countries.map((c) => c.value).includes(this.forms.participantForm.country.value)) {
        this.forms.participantForm.country.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobMember.value)
      ) {
        this.forms.additionalForm.cobMember.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobMother.value)
      ) {
        this.forms.additionalForm.cobMother.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobFather.value)
      ) {
        this.forms.additionalForm.cobFather.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobMotherGrandMother.value)
      ) {
        this.forms.additionalForm.cobMotherGrandMother.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobMotherGrandFather.value)
      ) {
        this.forms.additionalForm.cobMotherGrandFather.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobFatherGrandMother.value)
      ) {
        this.forms.additionalForm.cobFatherGrandMother.value = "";
      }
      if (
        !countriesWithPNTSOption
          .map((c) => c.value)
          .includes(this.forms.additionalForm.cobFatherGrandFather.value)
      ) {
        this.forms.additionalForm.cobFatherGrandFather.value = "";
      }

      this.usePhoto = this.current.profile.usePhoto || false;
      this.optinNrl = this.current.profile.optinNrl || false;
      this.optinTfa = this.current.profile.optinTfa || false;
    }
    this.$http
        .get("/nrl/api/v1/portal/getcountries?type=country")
        .then((res) => {
          this.LOADING(false);
          if (res && res.data) {
            this.countries = res.data.data.map(country => ({
            value : country.name,
            label : country.name,
          }));
            this.forms.participantForm.country.options = this.countries;
            this.forms.participantForm.country.validators = [choiceStrategy(this.countries)];
            this.onCountryChange(this.forms.participantForm.country.value);
          }
        }).catch(() => {
          this.PUSH_NOTIFICATION({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: "warning",
          });
          this.LOADING(false);
        });
  },
  watch: {
  // debounce method has been used
    'forms.participantForm.country.value': {
      handler: debounce(function (newVal) {
        if(newVal){ this.onCountryChange(newVal); }
      }, 300),
      immediate: true,
    },
    'forms.participantForm.state.value': {
      handler: debounce(function (newVal) {
        if(newVal){ this.onStateChange(newVal); }
      }, 300),
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("registration", [
      "current",
      "hasDocuments",
      "getAvailableProducts",
      "getCurrentMemberId",
      "getCurrentDocuments",
      "verificationPending",
    ]),
    ...mapGetters("user", ["userProfile"]),
    ...mapState("views", ["askForNewProfileCreation"]),
    ...mapState("registration", ["priceList"]),
   
    clearanceRequired() {
      if (!this.profileScreen) {
        const type = this.currentMemberType;

        const priceItem = this.priceList.filter(
          (item) => item.memberType && type && item.memberType.toLowerCase() === type.toLowerCase()
        )[0];

        return priceItem && priceItem.clearanceRequired;
      }
      return false;
    },
    currentProduct() {
      // Try mapping available products to the option selected before using the finder
      // Check that current is still available
      if (this.current.priceItem) {
        const foundProduct = this.getAvailableProducts.find(
          (product) => product.memberType === this.current.priceItem.memberType
        );
        return foundProduct || this.getAvailableProducts[0];
      }

      const foundProduct = this.getAvailableProducts.filter(
        (product) => product.memberType === this.currentMemberType
      )[0];

      if (this.current.entity && this.current.entity.entityType === "program") {
        return foundProduct && foundProduct.memberType
          ? foundProduct
          : this.getAvailableProducts[0];
      }

      return foundProduct && foundProduct.memberType ? foundProduct : undefined;
    },
    isTFA() {
      const { entity } = this.current;
      return (
        entity &&
        entity.orgtree &&
        entity.orgtree.national &&
        entity.orgtree.national._id &&
        entity.orgtree.national._id === 31
      );
    },
    activeMemberTypes() {
      if (this.currentProduct && this.currentProduct.memberType === "program") {
        return [
          {
            value: "program",
            label: "Program",
          },
        ];
      }
      return flow(
        filter((type) =>
          flow(
            map((product) => product.memberType),
            includes(type.type)
          )(this.getAvailableProducts)
        ),
        map((type) => ({
          value: type.type,
          label: type.name,
        }))
      )(memberTypes);
    },
    requireMedicalNotes() {
      return (
        this.forms.additionalForm.hasAllergies.value || this.forms.additionalForm.hasMedical.value
      );
    },
    currentMemberType() {
      return this.forms.typeOfRegistrationForm.typeOfRegistration.value || undefined;
    },
    requireWWC() {
      return ["coach", "volunteer", "trainer", "referee"].includes(this.currentMemberType);
    },
    memberTypeRequiresCOB() {
      return [
        "player",
        "player-league-tag",
        "player-league-touch",
        "player-touch",
        "player-representative",
        "player-masters",
        "player-social",
        "player-school",
        "program",
      ].includes(this.currentMemberType);
    },
    currentAddress() {
      return [
        this.forms.participantForm.addressLine1.value,
        this.forms.participantForm.addressLine2.value,
        this.forms.participantForm.suburb.value,
        this.forms.participantForm.state.value,
        this.forms.participantForm.postcode.value,
      ]
        .map((s) => `${s}, `)
        .concat([this.forms.participantForm.country.value]);
    },
    currentAddress_program() {
      return [
        this.forms.participantForm.postalAddressLine1.value,
        this.forms.participantForm.postalAddressLine2.value,
        this.forms.participantForm.postalSuburb.value,
        this.forms.participantForm.postalState.value,
        this.forms.participantForm.postalPostcode.value,
      ]
        .map((s) => `${s}, `)
        .concat([this.forms.participantForm.postalCountry.value]);
    },
    addressError() {
      if(this.forms.participantForm.suburb.errors[0] === "Invalid choice"){
         this.suburbInvalidChoiceError = true;
      }else {
        this.suburbInvalidChoiceError = false;
      }
      const concatE = flow(
        concat(this.forms.participantForm.addressLine1.errors),
        concat(this.forms.participantForm.addressLine2.errors),
        concat(this.forms.participantForm.suburb.errors),
        concat(this.forms.participantForm.state.errors),
        concat(this.forms.participantForm.postcode.errors)
      )([]);
      if(concatE.length !== 0) this.manualAddress = true;
      return concatE.length !== 0;
    },
    addressError_program() {
      const concatE = flow(
        concat(this.forms.participantForm.postalAddressLine1.errors),
        concat(this.forms.participantForm.postalAddressLine2.errors),
        concat(this.forms.participantForm.postalSuburb.errors),
        concat(this.forms.participantForm.postalState.errors),
        concat(this.forms.participantForm.postalPostcode.errors)
      )([]);
      return concatE.length !== 0;
    },
    genderDisabled() {
      return !!(
        this.forms.participantForm &&
        this.forms.participantForm.gender &&
        this.forms.participantForm.gender.value
      );
    },
    nationalId() {
      return this.current.entity &&
        this.current.entity.orgtree.national &&
        this.current.entity.orgtree.national._id === 31
        ? 31
        : 32;
    },
    showInternationalHeritage() {
      if (this.current.entity !== undefined && this.nationalId === 31) {
        return false;
      }
      return this.current.profile
        ? !isBelowAge(this.current.profile.participantForm.dob, 13) && this.memberTypeRequiresCOB
        : false;
    },
    showWWC() {
      if (
        !this.current.profile ||
        !this.current.profile.participantForm ||
        !this.current.profile.participantForm.dob ||
        this.$route.name !== "editprofile"
      )
        return false;
      return !this.isMinorConsistent(this.current.profile.participantForm.dob);
    },
    notProgram() {
      if (
        this.current &&
        this.current.entity &&
        this.current.entity.entityType &&
        this.current.entity.entityType === "program"
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions("views", ["triggerEvent"]),
    ...mapActions("registration", ["updateCurrent"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),
    ...mapMutations("root", ["LOADING"]),
    onCountryChange(newVal) {
      if(newVal){
      this.LOADING(true);
      this.$http
        .get(`/nrl/api/v1/portal/getcountries?type=state&countryName=${newVal}`)
        .then((res) => {
          if (res && res.data) {
            this.LOADING(false);
            this.states = [];
            this.allStates = [];
            this.allStates = res.data.data;
            this.states = res.data.data.map(state => ({
              value : state.states.name,
              label : state.states.name,
              state_code: state.states.state_code,
            }));
            this.forms.participantForm.state.options = this.states;
            this.forms.participantForm.state.validators = [choiceStrategy(this.states)];
            const stateValue = this.forms.participantForm.state.value;
            if(stateValue){
              this.forms.participantForm.state.value = this.findValueInArrayOfObject(this.states, this.forms.participantForm.state.value);
            }
            this.onStateChange(this.forms.participantForm.state.value);
          }
        }).catch(() => {
          this.PUSH_NOTIFICATION({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: "warning",
          });
          this.LOADING(false);
        });
      }
    },
    onStateChange(newVal) {
      if(newVal){
        this.cities = [];
        const city = this.allStates?.find(state => state.states.name.toLowerCase() === newVal.toLowerCase());
        const cityArray = city && city.states && city.states.cities;
        this.cities = cityArray?.map(city => ({
                value : city.name,
                label : city.name,
              }));
        this.forms.participantForm.suburb.options = this.cities;
        this.forms.participantForm.suburb.validators = [choiceStrategy(this.cities)];
      }
    },
    findValueInArrayOfObject(data, searchValue) {
      const foundObject = data.find(item => 
        Object.values(item).map(value => value.toString().toLowerCase()).includes(searchValue.toLowerCase())
    );
      return foundObject ? foundObject.value : undefined;
    },
    validateMobile(data) {
      if (!data || !data.number || !data.countryCode) {
        this.isValidInternationalMobile = false;
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode);
      if (parsedNumber.isValid()) {
        this.isValidInternationalMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number
        this.forms.participantForm.mobile.value = formattedNumber;
      } else {
        this.isValidInternationalMobile = false;
      }
      this.mobileMetaData = data;
    },
    handlePaste(number, phoneObject) {
      if (phoneObject.formatted) {
        this.forms.participantForm.mobile.value = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateMobile(phoneObject);
    },
    validateEmergencyMobile(data) {
      if (!data || !data.number || !data.countryCode) {
        this.isValidInternationalEmergencyMobile = false;
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode);
      if (parsedNumber.isValid()) {
        this.isValidInternationalEmergencyMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number
        this.forms.emergencyForm.mobile.value = formattedNumber;
        this.forms.emergencyForm.mobile.countryCode = data.countryCode;
        this.forms.emergencyForm.mobile.countryCallingCode = data.countryCallingCode;

      } else {
        this.isValidInternationalEmergencyMobile = false;
      }
    },
    handleEmergencyPaste(number, phoneObject) {
      if (phoneObject.formatted) {
        this.forms.emergencyForm.mobile.value = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateEmergencyMobile(phoneObject);
    },
    validateParentMobile(data) {
      if (!data | !data.number | !data.countryCode) {
        this.isValidInternationalParentMobile = false;
        return;
      }
      const parsedNumber = parsePhoneNumberFromString(data.number, data.countryCode);
      if (parsedNumber.isValid()) {
        this.isValidInternationalParentMobile = true;
        const formattedNumber = parsedNumber.formatInternational();

        // Update the model with the formatted number
        this.forms.parentForm.mobile.value = formattedNumber;
        this.forms.parentForm.mobile.countryCode = data.countryCode;
        this.forms.parentForm.mobile.countryCallingCode = data.countryCallingCode;
      } else {
        this.isValidInternationalParentMobile = false;
      }
    },
    handleParentPaste(number, phoneObject) {
      if (phoneObject.formatted) {
        this.forms.parentForm.mobile.value = phoneObject.formatted;
      }
      // Trigger the validation method
      this.validateParentMobile(phoneObject);
    },
    handleCancel() {
      if (!this.askForNewProfileCreation) {
        if (this.profileScreen) {
          this.$router.push({ name: "profile" });
        } else {
          this.$router.push({ name: "chooseprofile" });
        }
      } else {
        this.$router.go(-1);
      }
    },
    fillData() {
      // Binding default registration type
      // const { type } = this.current;
      // this.forms.typeOfRegistrationForm.typeOfRegistration.value = type;
      this.forms = populateFormset(this.forms, this.current.profile);
      if (this.forms.participantForm.email.value === "") {
        this.forms.participantForm.email.value = this.userProfile.email;
      }
      if (this.forms.parentForm !== undefined && this.forms.parentForm.email.value === "") {
        this.forms.parentForm.email.value = this.userProfile.email;
      }
      if (this.current.profile.meta && this.current.profile.meta.homePhone) {
        this.forms.participantForm.homePhone.value = this.current.profile.meta.homePhone;
      }
      this.componentKey += 1;
    },
    getPerson() {
      const person = this.current.profile;
      this.currentProfile = {
        id: person.id,
        avatar: person.avatar,
        firstName: person.participantForm.firstName,
        middleName: person.participantForm.middleName,
        lastName: person.participantForm.lastName,
        preferFirstName: person.participantForm.preferFirstName,
        preferLastName: person.participantForm.preferLastName,
      };
    },
    submitForm() {
      if (this.emergencySyncParent) {
        this.forms.emergencyForm = cloneDeep(this.forms.parentForm);
        delete this.forms.emergencyForm.email;
      }
      if(this.forms && this.forms.participantForm && this.forms.participantForm.mobile && this.forms.participantForm.mobile.value == ''){
        this.isValidInternationalMobile = false;
      }
      // Trigger the validation for all fields
      this.triggerEvent();

      // Skip verify parent form if it is not there
      const PARENT_DISPLAYED = isMinorConsistent(this.current.profile.participantForm.dob);

      // Validate the form with a single function
      if (!errorOrPassFormset(this.forms, null, this, window)) return;
  
      // Validate mobile number
      if (!this.isValidInternationalMobile) {
        this.$store.dispatch("views/notify", {
          msg: msg.error.mobileError,
          type: "warning",
          window,
        });
        return;
      }

      const profile = flattenFormset(this.forms);
     
      // Invalid date -- Passed from the mask pkg
      if (
        profile &&
        profile.additionalForm &&
        profile.additionalForm.wwcExpiry === "Invalid date"
      ) {
        profile.additionalForm.wwcExpiry = "";
      }

      if (profile.additionalForm.hasDisability === "") {
        delete profile.additionalForm.hasDisability;
      }

      // Form Profile Data
      let apiProfile = {
        ...profile.participantForm,
        ...profile.additionalForm,
        meta: { homePhone: profile.participantForm.homePhone },
        emergencyName: profile.emergencyForm.name,
        emergencyMobile: profile.emergencyForm.mobile,
        usePhoto: this.usePhoto,
        optinNrl: this.optinNrl,
        optinTfa: this.optinTfa,
      };

      let mobileMeta;

      if (this.mobileMetaData) {
        mobileMeta = {
          originalNumber: this.mobileMetaData.number,
          countryCode: this.mobileMetaData.countryCallingCode,
          countryRegion: this.mobileMetaData.countryCode,
          countryCode: this.mobileMetaData.countryCallingCode,
          number: this.mobileMetaData.number,
        };
        apiProfile.meta.mobile = mobileMeta;
      }

      if (PARENT_DISPLAYED) {
        apiProfile = Object.assign({}, apiProfile, {
          parent1Name: profile.parentForm.name,
          parent1Email: profile.parentForm.email,
          parent1Mobile: profile.parentForm.mobile,
        });
      }
      const type = profile.typeOfRegistrationForm.typeOfRegistration;

      // Repopulate priceItem or Copy new into current rego
      let { priceItem } = this.current;
      if (!priceItem || priceItem.memberType !== type) {
        priceItem = this.priceList.filter((x) => x.memberType === type)[0] || {};
      }

      const hasAdditionalQuestions =
        priceItem && priceItem.additionalQs && priceItem.additionalQs.length > 0;
      // Pull accross height and weight
      profile.participantForm = Object.assign(profile.participantForm, {
        height: this.current.profile.participantForm.height,
        weight: this.current.profile.participantForm.weight,
      });

      if (this.nationalId === 31) {
        const additionalDetails = Object.assign({}, this.current.additionalDetails, {
          optinTfa: this.optinTfa,
        });
        this.updateCurrent({ additionalDetails });
      }
      if (this.current.profile && this.current.profile.seasonPasses) {
        profile.seasonPasses = this.current.profile.seasonPasses;
      }
      if (this.currentProduct && this.currentProduct.memberType === 'program') {
        this.$confirm(
          "Please confirm your postal address before submitting your registration",
          "Are you sure?",
          {
            confirmButtonText: "Yes, Confirm",
            cancelButtonText: "Cancel",
          }
        )
          .then(() => {
            // Update current rego
            this.updateCurrent({
              type,
              profile: {
                ...profile,
                usePhoto: this.usePhoto,
                optinNrl: this.optinNrl,
                optinTfa: this.optinTfa,
                avatar: this.current.profile.avatar,
              },
              priceItem,
            });
            this.$store.commit("root/LOADING", true);
            this.$http
              .put(endpointParams.profile.update(this.current.id), apiProfile)
              .then((response) => {
                if (response.data && response.data.data === this.current.id) {
                  this.updateCurrent({
                    profileConfirmed: true,
                  });
                  this.$store.commit("root/LOADING", false);
                  if (this.profileScreen) {
                    this.$router.push({ name: "profile" });
                  } else if (
                    (!PARENT_DISPLAYED &&
                      this.current.type === "player-masters" &&
                      this.current.entity._id === 250272) ||
                    hasAdditionalQuestions
                  ) {
                    this.$router.push({ name: "additionaldetails" });
                  } else if (
                    !PARENT_DISPLAYED &&
                    [
                      "player-league-tag",
                      "player-league-touch",
                      "player-representative",
                      "player-masters",
                      "player-social",
                      "player-school",
                      "player",
                      "non-participating-member",
                    ].includes(this.current.type) &&
                    !hasAdditionalQuestions
                  ) {
                    this.updateCurrent({ additionalDetails: true });
                    this.$router.push({ name: "chooseaddon" });
                  } else {
                    this.$router.push({ name: "additionaldetails" });
                  }
                } else {
                  this.$store.commit("root/LOADING", false);
                  window.scrollTo(0, 0);
                  this.PUSH_NOTIFICATION({
                    msg: msg.error.apiError,
                    type: "warning",
                  });
                }
              })
              .catch((error) => {
                this.$store.commit("root/LOADING", false);
                window.scrollTo(0, 0);
                this.PUSH_NOTIFICATION({
                  msg:
                    error.response && error.response.data && error.response.data.message
                      ? error.response.data.message
                      : msg.error.apiError,
                  type: "warning",
                });
              });
          })
          .catch(() => {
            // Handle the case where the confirmation is canceled
          });
      } else {
        // Update current rego without confirmation
        this.updateCurrent({
          type,
          profile: {
            ...profile,
            usePhoto: this.usePhoto,
            optinNrl: this.optinNrl,
            optinTfa: this.optinTfa,
            avatar: this.current.profile.avatar,
          },
          priceItem,
        });
        this.$store.commit("root/LOADING", true);
        this.$http
          .put(endpointParams.profile.update(this.current.id), apiProfile)
          .then((response) => {
            if (response.data && response.data.data === this.current.id) {
              this.updateCurrent({
                profileConfirmed: true,
              });
              this.$store.commit("root/LOADING", false);
              if (this.profileScreen) {
                this.$router.push({ name: "profile" });
              } else if (
                (!PARENT_DISPLAYED &&
                  this.current.type === "player-masters" &&
                  this.current.entity._id === 250272) ||
                hasAdditionalQuestions
              ) {
                this.$router.push({ name: "additionaldetails" });
              } else if (
                !PARENT_DISPLAYED &&
                [
                  "player-league-tag",
                  "player-league-touch",
                  "player-representative",
                  "player-masters",
                  "player-social",
                  "player-school",
                  "player",
                  "non-participating-member",
                ].includes(this.current.type) &&
                !hasAdditionalQuestions
              ) {
                this.updateCurrent({ additionalDetails: true });
                this.$router.push({ name: "chooseaddon" });
              } else {
                this.$router.push({ name: "additionaldetails" });
              }
            } else {
              this.$store.commit("root/LOADING", false);
              window.scrollTo(0, 0);
              this.PUSH_NOTIFICATION({
                msg: msg.error.apiError,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.$store.commit("root/LOADING", false);
            window.scrollTo(0, 0);
            this.PUSH_NOTIFICATION({
              msg:
                error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : msg.error.apiError,
              type: "warning",
            });
          });
        }

    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData(addressData) {
      this.forms.participantForm.addressLine1.value =
        (addressData.street_number ? `${addressData.street_number} ` : "") + addressData.route;
      this.forms.participantForm.addressLine2.value = "";
      this.forms.participantForm.suburb.value = addressData.locality;
      const filteredStates = this.allStates.find(state => state.states.name.toLowerCase() === addressData.administrative_area_level_1.toLowerCase() || state.states.state_code.toLowerCase() === addressData.administrative_area_level_1.toLowerCase());
      this.forms.participantForm.state.value =  filteredStates?.states?.name ?? '';
      this.forms.participantForm.postcode.value = addressData.postal_code;
      this.forms.participantForm.country.value = addressData.country;
      this.componentKey += 1;
    },
    getAddressData_postal(addressData) {
      this.forms.participantForm.postalAddressLine1.value =
        (addressData.street_number ? `${addressData.street_number} ` : "") + addressData.route;
      this.forms.participantForm.postalAddressLine2.value = "";
      this.forms.participantForm.postalSuburb.value = addressData.locality;
      this.forms.participantForm.postalState.value = addressData.administrative_area_level_1;
      this.forms.participantForm.postalPostcode.value = addressData.postal_code;
      this.forms.participantForm.postalCountry.value = addressData.country;
      this.componentKey += 1;
    },
    toggleManualAddress() {
      this.manualAddress = !this.manualAddress;
      this.componentKey += 1;
    },
    toggleManualAddress_program() {
      this.manualAddress_program = !this.manualAddress_program;
      this.componentKey += 1;
    },
    fillAddress() {
      this.forms.participantForm.postalAddressLine1.value =
        this.forms.participantForm.addressLine1.value;
      this.forms.participantForm.postalAddressLine2.value =
        this.forms.participantForm.addressLine2.value;
      this.forms.participantForm.postalSuburb.value = this.forms.participantForm.suburb.value;
      this.forms.participantForm.postalState.value = this.forms.participantForm.state.value;
      this.forms.participantForm.postalPostcode.value = this.forms.participantForm.postcode.value;
      this.forms.participantForm.postalCountry.value = this.forms.participantForm.country.value;
      this.componentKey += 1;
    },
    formatMoney,
    imgEndpoint(id) {
      return endpointParams.logo(id);
    },
    isBelowAge,
  },
  data() {
    return {
      countries: [],
      states: [],
      cities: [],
      allStates:[],
      preferredCountries: ["AU", "NZ", "WS", "TO", "PG", "CK", "US", "FJ", "GB", "FR"],
      suburbInvalidChoiceError: false,
      props: {
        showSearchBox: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      mobileMetaData: undefined,
      isValidInternationalMobile: true,
      isValidInternationalEmergencyMobile:true,
      isValidInternationalParentMobile: true,
      displayUploadDocuments: false,
      isMinorConsistent,
      internationalHeritage: false,
      forms: {
        typeOfRegistrationForm: makeForm({
          typeOfRegistration: {
            component: "SelectSP",
            label: "Type of Registration",
            options: [
              ...typeOfRegistrationOptions.player,
              ...typeOfRegistrationOptions.volunteer,
              ...typeOfRegistrationOptions.referee,
            ],
            value: undefined,
            displayed: () => !this.profileScreen,
          },
        }),
        participantForm: makeForm({
          firstName: { label: "First Name", required: false },
          middleName: { label: "Middle Name", required: false },
          lastName: { label: "Last Name", required: false },
          preferFirstName: {
            label: "Preferred First Name (optional)",
            required: false,
          },
          preferLastName: {
            label: "Preferred Last Name (optional)",
            required: false,
          },
          dob: { component: "DateInput", label: "Birthday" },
          gender: {
            component: "SelectSP",
            label: "Gender",
            options: genderOptions,
          },
          email: { label: "Email", validators: [emailStrategy] },
          mobile: { label: "Mobile", require: true, },
          homePhone: {
            label: "Home Phone (Optional)",
            required: false,
            displayed: () => this.current.entity !== undefined && this.nationalId === 31,
          },
          addressLine1: { label: "Address Line 1" },
          addressLine2: { label: "Address Line 2 (optional)", required: false },
          suburb: { label: "City / Suburb",
          component: "TypedSelect",
          validators: [],
          options: [],
          },
          state: { label: "State",
            component: "TypedSelect",
            validators: [],
            options: [],
           },
          postcode: { label: "Postcode" },
          country: {
            component: "TypedSelect",
            validators: [], 
            label: "Country / Area",
            options: [],
          },
          postalAddressLine1: {
            label: "Address Line 1",
            displayed: () =>
              this.current.entity !== undefined && this.current.entity.entityType === "program",
          },
          postalAddressLine2: {
            label: "Address Line 2 (optional)",
            required: false,
            displayed: () =>
              this.current.entity !== undefined && this.current.entity.entityType === "program",
          },
          postalSuburb: {
            label: "Suburb",
            displayed: () =>
              this.current.entity !== undefined && this.current.entity.entityType === "program",
          },
          postalState: {
            label: "State",
            displayed: () =>
              this.current.entity !== undefined && this.current.entity.entityType === "program",
          },
          postalPostcode: {
            label: "Postcode",
            displayed: () =>
              this.current.entity !== undefined && this.current.entity.entityType === "program",
          },
          postalCountry: {
            component: "TypedSelect",
            validators: [choiceStrategy(countries)],
            label: "Country / Area",
            options: countries,
            displayed: () =>
              this.current.entity !== undefined && this.current.entity.entityType === "program",
          },
          isPrivate: {
            label: "Make Profile Private? *",
            options: [
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ],
            component: "SelectSP",
          },
        }),
        emergencyForm: makeForm({
          name: { label: "Full Name" },
          mobile: {
            label: "Mobile",
            required: true
          },
        }),
        parentForm: makeForm({
          name: {
            label: "Full Name",
            displayed: () => isMinorConsistent(this.current.profile.participantForm.dob),
          },
          email: {
            label: "Email",
            validators: [emailStrategy],
            displayed: () => isMinorConsistent(this.current.profile.participantForm.dob),
          },
          mobile: {
            label: "Mobile",
            required: true,
            displayed: () => isMinorConsistent(this.current.profile.participantForm.dob),
          },
        }),
        additionalForm: makeForm({
          ethnicity: {
            component: "SelectSP",
            label: "Ethnicity",
            options: ethnicityOptions,
          },
          cobMember: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () => this.memberTypeRequiresCOB,
          },
          cobMother: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Parent 1's Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () => this.memberTypeRequiresCOB,
          },
          cobFather: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Parent 2's Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () => this.memberTypeRequiresCOB,
          },
          cobMotherGrandMother: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Grandparent 1's (Parent 1) Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () =>
              this.internationalHeritage &&
              this.memberTypeRequiresCOB &&
              (this.current.entity === undefined || this.nationalId === 32),
          },
          cobMotherGrandFather: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Grandparent 2's (Parent 1) Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () =>
              this.internationalHeritage &&
              this.memberTypeRequiresCOB &&
              (this.current.entity === undefined || this.nationalId === 32),
          },
          cobFatherGrandMother: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Grandparent 1's (Parent 2) Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () =>
              this.internationalHeritage &&
              this.memberTypeRequiresCOB &&
              (this.current.entity === undefined || this.nationalId === 32),
          },
          cobFatherGrandFather: {
            component: "TypedSelect",
            validators: [choiceStrategy(countriesWithPNTSOption)],
            label: "Grandparent 2's (Parent 2) Country / Area of Birth",
            options: countriesWithPNTSOption,
            displayed: () =>
              this.internationalHeritage &&
              this.memberTypeRequiresCOB &&
              (this.current.entity === undefined || this.nationalId === 32),
          },

          hasAllergies: {
            label: "Allergies?",
            options: [
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ],
            component: "SelectSP",
          },
          allowedTreatment: {
            label: "Provide First Aid?",
            options: [
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ],
            component: "SelectSP",
          },
          hasMedical: {
            label: "Medical Conditions?",
            options: [
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ],
            component: "SelectSP",
          },
          notes: {
            label: "Medical Notes",
            displayed: () => this.requireMedicalNotes,
          },
          hasDisability: {
            label: "Do you have a disability?",
            options: [
              {
                value: "yes",
                label: "Yes",
              },
              {
                value: "no",
                label: "No",
              },
              {
                value: "prefer-not-answer",
                label: "Prefer not to say",
              },
            ],
            component: "SelectSP",
            displayed: () => this.current.entity !== undefined && this.nationalId === 31,
            required: false,
          },
          disabilityType: {
            label: "What type of disability?",
            options: disabilityTypes,
            component: "SelectSP",
            displayed: () =>
              this.current.entity !== undefined &&
              this.nationalId === 31 &&
              this.forms.additionalForm.hasDisability.value === "yes",
          },
          wwcNumber: {
            label: "WWC Number",
            displayed: () => this.requireWWC || this.showWWC,
            required: false,
          },
          wwcIssuer: {
            label: "WWC State Issuer",
            options: states,
            component: "SelectSP",
            displayed: () => this.requireWWC || this.showWWC,
            required: false,
          },
          wwcExpiry: {
            component: "DateInput",
            label: "WWC Expiry Date",
            required: false,
            displayed: () => this.requireWWC || this.showWWC,
          },
        }),
      },
      emergencySyncParent: false, // controls player-minor form sync with parent
      currentProfile: {},
      componentKey: 1,
      emergencyIsTheSameAsParent: false,
      usePhoto: false,
      optinNrl: false,
      optinTfa: false,
      manualAddress: false,
      manualAddress_program: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-address {
  cursor: pointer;
  color: $secondary;
  font-size: 0.85rem;

  &:hover,
  &:focus {
    color: $rl-highlight !important;
  }

  &.red {
    color: red;
  }
}

.invalidMobile {
  color: red;
  font-size: small;
}

.mobile {
  font-size: small;
  margin-bottom: -16px;
  color: gray;
}

.left {
  padding-left: 0;
}

h4 {
  font-family: $fontBold;
}

.rel-wrap {
  position: relative;
}

.warning {
  color: red;
}

.alert {
  z-index: 5;
}

.data-table {
  margin-bottom: 2rem;
}

.upload-modal {
  padding: 1rem;
}

.header-bg {
  background-image: url("/bg/my_account_header.png");
  background-size: 100% 100%;
  height: 400px;
  width: calc(100% + 30px);
  position: absolute;
  top: -40px;
  z-index: 0;
}

.profile {
  z-index: 9;
}

.current-address {
  & + div {
    margin-top: 5px;
  }

  div {
    width: 100%;
    text-align: right;
  }

  span {
    font-size: 0.875rem;
  }
}

.f-14 {
  font-size: 0.875rem;
}
</style>
